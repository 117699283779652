import { CanActivate, Router, CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { MessageService } from '../message/message.service';
import { SuccessErrorConst } from 'src/app/core/successErrorConst';
import { RouteService } from '../route.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutGuard implements CanLoad {

  constructor(
    private router: Router,
    private message: MessageService,
    private routeService: RouteService
  ) { }

  canLoad(): boolean {
    if (!!this.routeService.getToken()) {
      return true;
    }
    localStorage.clear();
    this.router.navigate(['/customers'])
    // this.message.alert('info', SuccessErrorConst.loginAgin);
    return false;
  }

}
