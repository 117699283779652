import { Injectable } from '@angular/core';
import { GlobalVariable } from '../core/global';

import jwt_decode from "jwt-decode";
// import * as jwt_decode from "jwt-decode";
import { PERMISSIONS, readAccess, SUBVENDOR_TYPE, menuItemsJson } from '../shared/models/route-model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  
  constructor(
    private router: Router
  ) { }

  decodeToken() : any {
    return jwt_decode(this.getToken());
  }

  // localstorage setvalue, clear and get value start
  getToken() : any {
    if (!!localStorage.getItem(GlobalVariable.tokenKey)) {
      return localStorage.getItem(GlobalVariable.tokenKey)
    } else {
      return '';
    }
  }

  goToFirstRouteCall() {
    let tokenData = this.decodeToken();
    if (!!tokenData.superAdmin) {
      this.router.navigate(['/dashboard']);
    } else {
      let type = this.getFirstViewPermissionType(tokenData);
      if (type != '') {
        this.getFirstRoute(menuItemsJson, type);
      }
    }
  }

  getFirstViewPermissionType(tokenData) {
    tokenData.permissions = JSON.parse(tokenData.permissions);

    let data = tokenData.permissions.find(permission => permission.check.includes(PERMISSIONS[readAccess]));
    if (data) {
      return data.type;
    } else {
      return '';
    }
  }

  getFirstRoute(routes, type) {

    for (let index = 0; index < routes.length; index++) {
      if (routes[index].children && routes[index].children.length) {
        this.getFirstRoute(routes[index].children, type);
      } else {
        if(SUBVENDOR_TYPE[routes[index].id] == type) {
          this.router.navigate([routes[index].routerLink]);
          return;
        }
      }
    }
  }

  checkPermission(type, permission) {
    let tokenData = this.decodeToken();
    if(tokenData.superAdmin) {
      return true;
    } else {
      tokenData.permissions = JSON.parse(tokenData.permissions);
      let index = tokenData.permissions.findIndex(data => data.type == type);
      if(index > -1) {
        return tokenData.permissions[index].check.includes(permission);
      }
    }
    return false;
  }

}
