export const GlobalVariable = {

	SITE_NAME: "Reward Admin",
	PATTERNS: {
		email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
	},
	tokenKey: 'reward_admin',
	endPriceRangeProduct: 5000

}
