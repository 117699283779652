const permissionsadmin = ['view', 'add', 'update', 'delete'];

export const readAccess = 'view';
export const addAccess = 'add';
export const editAccess = 'update';
export const deleteAccess = 'delete';

export const PERMISSIONS = {
    view: 1,
    add: 2,
    update: 3,
    delete: 4
}

export const SUBVENDOR_TYPE = {
    dashboard : 1,
    users : 2,
    vitrines : 3,
    sub_admin : 4,
    products : 5,
    categories : 6,
    sub_categories : 7,
    product_sizes : 8,
    courier_service : 9,
    vitrine_company_size: 10,
    countries: 11,
    coverage_area: 12,
    interest: 13,
    banners_main: 14,
    discount_offers: 15,
    advertisement_plans: 16,
    plans: 17,
    plan_downgrade_requests: 18,
    orders: 19,
    transactions: 20,
    feeds: 21,
    notifications: 22,
    news_letter_subscriber: 23,
    intellectual_property_policy: 24,
    vitrines_owner_policies: 25,
    vitriners_users_Policies: 26,
    social_media_feed_policy: 27,
    website_disclaimers: 28,
    privacy_policy: 29,
    terms_of_use: 30,
    cookies_policy: 31,
    contact_us: 32,
    defaults: 33,
    press_banner: 34,
    press: 35,
    team: 36,
    news: 37,
    what_new: 38,
    faqs: 39,
    contact_us_reason: 40,
    news_update: 41,
    discount_coupon: 42,
    bundle_plans: 43,
    transfer_requests: 44,
    currency: 45,
    return_reason: 45,
    career: 46,
    product_colors: 47,
    processing_time: 48
}

export const menuItemsJson = [
    {
        name: 'Customers',
        routerLink: '/customers',
        id: 'users',
        icon: 'fa-users',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    },
    {
        name: 'Categories',
        routerLink: '/categories',
        id: 'users',
        icon: 'fa-list-alt',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    },
    {
        name: 'Sales Person',
        routerLink: '/sales-person',
        id: 'users',
        icon: 'fa-user',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    },
    {
        name: 'Vendors',
        routerLink: '/vendors',
        id: 'users',
        icon: 'fa-users',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    },
    {
        name: 'Leads',
        routerLink: '/leads',
        id: 'leads',
        icon: 'fa-bullhorn',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    },
    {
        name: 'Items Per Points',
        routerLink: '/items-per-points',
        id: 'leads',
        icon: 'fa-hand-o-right',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    },
    {
        name: 'News',
        routerLink: '/news',
        id: 'leads',
        icon: 'fa-newspaper-o',
        permissions: addpermissions(['view', 'add', 'update', 'delete']),
        children: [],
        status: true
    }
];

// addpermissions read, write, add
function addpermissions(permissions: string[]) {
    const array = [];
    permissions.forEach((item) => {
        if (permissionsadmin.indexOf(item) !== -1) {
            array.push({ label: getlabels(item), value: item, status: true, alreadySelected: true });
        }
    });
    return array;
}

// get permissions labels
function getlabels(name) {
    switch (name) {
        case 'view':
            {
                return 'View';
            }
        case 'delete':
            {
                return 'Delete';
            }
        case 'add':
            {
                return 'Add';
            }
        case 'update':
            {
                return 'Update';
            }
        default:
            return 'Others';
    }
}
