import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { RouteService } from '../route.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(
        protected router: Router,
        private routeService: RouteService
    ) { }

    canLoad() {
        if (!this.routeService.getToken()) {
            return true;
        }
        this.router.navigate(['/customers']);
        // this.routeService.goToFirstRouteCall();
        return false;
    }

}
