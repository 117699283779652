// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  apiBaseUrl: 'https://reward-platform-apidev.netsolutionindia.com',
  firebase: {
    apiKey: "AIzaSyC7Lzk979wBPOlvFY-gv5HwdMmDMqONgs8",
    authDomain: "myvitrines-4cc78.firebaseapp.com",
    databaseURL: "https://myvitrines-4cc78.firebaseio.com",
    projectId: "myvitrines-4cc78",
    storageBucket: "myvitrines-4cc78.appspot.com",
    messagingSenderId: "136189856287",
    appId: "1:136189856287:web:507c3990cbce55ee6d632a",
    measurementId: "G-0D4J261N8J"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
